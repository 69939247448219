import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    deleteCarrierAPI,
    findOperatorsBySearchDataAPI,
    findSubOperatorsBySearchDataAPI,
    getAllOperatorsAPI,
    getAllSubOperatorsAPI,
    insertOperatorAPI,
    updateOperatorAPI
} from "../../api/operators";
import {getCooperatorsByLineIdAPI} from "../../api/cooperator";

export const getAllOperators = createAsyncThunk('getAllOperators', async (payload) => {
    return await getAllOperatorsAPI(payload)
})
export const getAllSubOperators = createAsyncThunk('getAllSubOperators', async () => {
    return await getAllSubOperatorsAPI()
})

export const getAllCooperators = createAsyncThunk('getAllCooperators', async (payload) => {
    const response = (await getCooperatorsByLineIdAPI(payload)).data
    return response.map(r => r.operator)
})

export const findOperatorsBySearchData = createAsyncThunk('findOperatorsBySearchData', async (payload) => {
    return await findOperatorsBySearchDataAPI(payload)
})

export const findSubOperatorsBySearchData = createAsyncThunk('findSubOperators', async (payload) => {
    return await findSubOperatorsBySearchDataAPI(payload)
})
export const insertOperator = createAsyncThunk('insertOperator', async (payload) => {
    return await insertOperatorAPI(payload)
})
export const updateOperator = createAsyncThunk('updateOperatorAPI', async (payload) => {
    return await updateOperatorAPI(payload)
})

export const deleteCarrier = createAsyncThunk('deleteCarrier', async (id, {rejectWithValue}) => {
    try {
        const response = await deleteCarrierAPI(id);
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        }
        return rejectWithValue(response.data);
    } catch (error) {
        if (error.data) {
            return rejectWithValue(error.data);
        }
        return rejectWithValue("serverErrorOccurred");
    }
})

const initialState = {
    operatorsBE: [], // svi operateri koje koristim na ostali stranicama
    foundOperators: [], // od search-a
    foundSubOperators: [], // od search-a
    cooperators: [], // od search-a
    allSubOperators: [],
    loadingOperatorsBe: false,
    loadingCooperators: false,
}

export const operatorsBESlice = createSlice({
    name: 'operatorsBE',
    initialState,
    reducers: {
        specialCarrierLoading: (state, {payload}) => {
            if (payload) {
                state.loadingOperatorsBe = payload
            }
        },
        searchCarriersSlice: (state, {payload}) => {
            if (payload?.data) {
                state.foundOperators = payload?.data;
                state.loadingOperatorsBe = false
            }
        },
        searchSubOperatorsSlice: (state, {payload}) => {
            if (payload?.data) {
                state.foundSubOperators = payload.data;
                state.loadingOperatorsBe = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSubOperators.pending, (state) => {
                state.loadingOperatorsBe = true;
            })
            .addCase(getAllSubOperators.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.allSubOperators = payload.data;
                }
                state.loadingOperatorsBe = false;
            })
            .addCase(getAllSubOperators.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(insertOperator.pending, (state) => {
                state.loadingOperatorsBe = true;
            })
            .addCase(insertOperator.fulfilled, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(insertOperator.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(getAllCooperators.pending, (state) => {
                state.loadingCooperators = true;
            })
            .addCase(getAllCooperators.fulfilled, (state, {payload}) => {
                state.cooperators = payload
                state.loadingCooperators = false;
            })
            .addCase(getAllCooperators.rejected, (state) => {
                state.loadingCooperators = false;
            })
            .addCase(updateOperator.pending, (state) => {
                state.loadingOperatorsBe = true;
            })
            .addCase(updateOperator.fulfilled, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(updateOperator.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(getAllOperators.pending, (state) => {
                state.loadingOperatorsBe = true;
            })
            .addCase(getAllOperators.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundOperators = payload.data;
                    state.operatorsBE = payload.data;
                }
                state.loadingOperatorsBe = false;
            })
            .addCase(getAllOperators.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(findSubOperatorsBySearchData.pending, (state) => {
                state.loadingOperatorsBe = true;
            })
            .addCase(findSubOperatorsBySearchData.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundSubOperators = payload.data;
                    state.operatorsBE = payload.data;
                }
                state.loadingOperatorsBe = false;
            })
            .addCase(findSubOperatorsBySearchData.rejected, (state) => {
                state.loadingOperatorsBe = false;
            })
            .addCase(deleteCarrier.fulfilled, (state, {meta}) => {
                let content = state.foundOperators.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(line => line.id === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundOperators.totalElements -= 1;
                        state.foundOperators.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(findOperatorsBySearchData.pending, (state) => {
                state.loadingOperatorsBe = true;
            })
            .addCase(findOperatorsBySearchData.fulfilled, (state, {payload}) => {
                if (payload?.data) {
                    state.foundOperators = payload.data;
                }
                state.loadingOperatorsBe = false;
            })
            .addCase(findOperatorsBySearchData.rejected, (state) => {
                state.loadingOperatorsBe = false;
            });
    }
});

export const {searchCarriersSlice, searchSubOperatorsSlice, specialCarrierLoading} = operatorsBESlice.actions;

export const operatorsBEReducer = operatorsBESlice.reducer
