import React, {useState, useEffect} from 'react';
import Dialog from "@mui/material/Dialog";
import {DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {insertExceptionRateAPI} from "../../../api/rate";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {fCurrency} from "../../../utils/formatNumber";

export default function PriceChangeDialog({loading, setLoading, open, handleClose, data}) {
    const {enqueueSnackbar} = useSnackbar();
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        const {value} = e.target;
        setValue(value);
    };

    const handleSave = () => {
        setLoading(true);
        const copy = {...data};

        if (data.key === "priceOneWay") {
            copy.priceOneWay = value || null;
        } else if (data.key === "returnTicketPrice") {
            copy.priceReturnTicket = value || null;
        } else if (data.key === "priceMonthly") {
            copy.priceMonthly = value || null;
        }

        insertExceptionRateAPI(copy)
            .then(_ => {
                enqueueSnackbar("Uspešno", {variant: "success"});
                handleClose({stationId: data.station2Id, key: data.key, ticketTypeId: data.ticketTypeId});
            })
            .catch(e => {
                console.error(e, 'EE');
                enqueueSnackbar("Greška", {variant: "error"});
            })
            .finally(() => {
                setLoading(false);
            });
    };


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSave();
            }
        };

        if (open) {
            window.addEventListener('keypress', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [open, handleSave]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            sx={{
                backdropFilter: 'blur(12px)',
                '& .MuiDialog-paper': {
                    borderRadius: '16px',
                    padding: '24px',
                },
            }}
        >
            <DialogTitle sx={{textAlign: 'center', fontWeight: 'bold'}}>
                Izmena cene
            </DialogTitle>
            <DialogContent sx={{mt: 2}}>
                <Grid container spacing={2} sx={{mb: 2}}>
                    <Grid item xs={4}>
                        <Typography variant="body1" fontWeight="bold">Naziv popusta:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{data?.label}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" fontWeight="bold">Jedan smer:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{fCurrency(data?.priceOneWay?.toString()) || '/'}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" fontWeight="bold">Povratna karta:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{fCurrency(data?.priceReturnTicket?.toString()) || '/'}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" fontWeight="bold">Mesečna karta:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{fCurrency(data?.monthlyPrice?.toString()) || '/'}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" fontWeight="bold">Stanica:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{data?.stationName || '/'}</Typography>
                    </Grid>
                </Grid>
                <TextField
                    label="Nova cena"
                    value={value}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{mt: 2}}
                />
            </DialogContent>
            <DialogActions sx={{justifyContent: 'center', mt: 1}}>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="medium"
                    sx={{borderRadius: '12px', width: 150}}
                    disabled={loading}
                >
                    Zatvori
                </Button>
                <LoadingButton
                    onClick={handleSave}
                    loading={loading}
                    variant="contained"
                    size="medium"
                    sx={{borderRadius: '12px', width: 150}}
                >
                    Sačuvaj
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
