import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    deletePlatformAPI,
    fetchPlatformsBusynessAPI,
    getAllPlatformsByStationIdAPI,
    insertNewPlatformAPI,
    updatePlatformAPI
} from "../../api/platform";

export const fetchPlatformsBusyness = createAsyncThunk('fetchPlatformsBusyness',
    async ({stationId, platformId, sortOrder}) => {
        return (await fetchPlatformsBusynessAPI(stationId, platformId, sortOrder)).data
    })

export const fetchAllPlatformsByStationId = createAsyncThunk('fetchAllPlatformsByStationId', async (stationId) => {
    return (await getAllPlatformsByStationIdAPI(stationId)).data
})

export const insertNewPlatform = createAsyncThunk('insertNewPlatform', async (data) => {
    return (await insertNewPlatformAPI(data)).data
})

export const deletePlatform = createAsyncThunk('deletePlatform', async (data) => {
    return (await deletePlatformAPI(data)).data
})

export const updatePlatform = createAsyncThunk('updatePlatform', async (data) => {
    return (await updatePlatformAPI(data)).data
})


const initialState = {
    foundPlatforms: undefined,
    loadingPlatforms: false,
    busyness: []
}

const platformSlice = createSlice({
    name: 'platforms',
    initialState,
    reducers: {
        specialPlatformLoading: (state, {payload}) => {
            if (payload) {
                state.loadingPlatforms = payload
            }
        },
        searchPlatformSlice: (state, {payload}) => {
            if (payload?.content) {
                state.foundPlatforms = payload;
                state.loadingPlatforms = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPlatformsByStationId.pending, (state) => {
                state.loadingPlatforms = true;
            })
            .addCase(fetchAllPlatformsByStationId.fulfilled, (state, {payload}) => {
                state.foundPlatforms = payload;
                state.loadingPlatforms = false;
            })
            .addCase(fetchAllPlatformsByStationId.rejected, (state) => {
                state.loadingPlatforms = false;
            })
            .addCase(insertNewPlatform.pending, (state) => {
                state.loadingPlatforms = true;
            })
            .addCase(insertNewPlatform.fulfilled, (state, {payload}) => {
                state.loadingPlatforms = false;
            })
            .addCase(insertNewPlatform.rejected, (state) => {
                state.loadingPlatforms = false;
            })
            .addCase(updatePlatform.pending, (state) => {
                state.loadingPlatforms = true;
            })
            .addCase(updatePlatform.fulfilled, (state, {payload}) => {
                const content = state.foundPlatforms.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex((platforms) => platforms.id === payload.id);
                    if (index !== -1) {
                        content[index] = payload;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(updatePlatform.rejected, (state) => {
                state.loadingPlatforms = false;
            })
            .addCase(deletePlatform.fulfilled, (state, {meta}) => {
                let content = state.foundPlatforms.content;
                if (Array.isArray(content)) {
                    const index = content.findIndex(platforms => platforms.id === meta.arg);
                    if (index !== -1) {
                        content.splice(index, 1);
                        state.foundPlatforms.totalElements -= 1;
                        state.foundPlatforms.numberOfElements = content.length;
                    }
                } else {
                    console.error('Content is not an array:', content);
                }
            })
            .addCase(fetchPlatformsBusyness.pending, (state) => {
                state.loadingBusyness = true;
            })
            .addCase(fetchPlatformsBusyness.fulfilled, (state, {payload}) => {
                state.busyness = payload;
                state.loadingBusyness = false;
            })
            .addCase(fetchPlatformsBusyness.rejected, (state) => {
                state.loadingBusyness = false;
            });
    },
});

export const {searchPlatformSlice, specialPlatformLoading} = platformSlice.actions;
export default platformSlice.reducer;

